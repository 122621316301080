import { Box } from '@mui/material'
import { GlobalPopup, globalPopupRef } from 'components/GlobalPopup'
import React from 'react'
import { ToastContainer } from 'react-toastify'

export interface Props {
  children?: React.JSX.Element | React.JSX.Element[] | any[]
}
export function MainContent({ children }: Props) {
  return (
    <Box sx={{ backgroundColor: '#F7F7F7' }}>
      <GlobalPopup ref={globalPopupRef} />
      <Box sx={{ p: '16px 24px', position: 'relative' }}>{children}</Box>
      <ToastContainer />
    </Box>
  )
}
