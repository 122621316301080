/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { NoticeArrival, NoticeArrivalItem, NoticeArrivalState } from 'models'

const initialState: NoticeArrivalState = {
  data: {},
  import_products: []
}
const slice = createSlice({
  name: 'NOTICE_ARRIVAL_STATE',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<Partial<NoticeArrival>>) => {
      state.data = payload as any
    },
    setImportProducts: (state, { payload }: PayloadAction<NoticeArrivalItem[]>) => {
      state.import_products = payload
    },
    setField: (state, { payload }: PayloadAction<Partial<NoticeArrival>>) => {
      state.data = {
        ...state.data,
        ...(payload as any)
      }
    }
  }
})
export const { actions: noticeArrivalActions, reducer: noticeArrivalReducer } = slice
