/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CampaignEntity, CampaignStateEntity, EnumCampaignTab, ReportState, OrgUnitEntity, ProductFieldKey, ProductFields } from 'models'

const getAreaStoreIds = (areas: OrgUnitEntity[], area_id: string) => {
  return areas.find((area) => area.id === area_id)?.children.map((store) => store.id) ?? []
}
const initialState: ReportState = {
  product_fields: [],
  include_comments: false,
  include_overall_order: false,
  areas: [],
  selected_areas: [],
  selected_stores: []
}
const slice = createSlice({
  name: 'ORDER_EXPORT_STATE',
  initialState,
  reducers: {
    selectProductField: (state, { payload }: PayloadAction<ProductFieldKey>) => {
      const selected = state.product_fields.includes(payload)
      console.log('Selected field : ', payload, selected)
      if (selected) {
        state.product_fields = state.product_fields.filter((item) => item !== payload)
      } else {
        state.product_fields = [...state.product_fields, payload]
      }

      state.product_fields = ProductFields.filter((field) => state.product_fields.includes(field))
    },
    setSelectedProductField: (state, { payload }: PayloadAction<ProductFieldKey[]>) => {
      state.product_fields = payload
    },
    selectComment: (state) => {
      state.include_comments = !state.include_comments
    },
    selectOverallOrder: (state) => {
      state.include_overall_order = !state.include_overall_order
    },
    setSelectedAreas: (state, { payload }: PayloadAction<string[]>) => {
      state.selected_areas = payload
    },
    setAreas: (state, { payload }: PayloadAction<OrgUnitEntity[]>) => {
      state.areas = payload
    },
    selectArea: (state, { payload }: PayloadAction<string>) => {
      const selected = state.selected_areas.includes(payload)
      if (selected) {
        state.selected_areas = state.selected_areas.filter((item) => item !== payload)
      } else {
        state.selected_areas.push(payload)
      }
    },
    selectStore: (state, { payload }: PayloadAction<string>) => {
      const selected = state.selected_stores.includes(payload)
      if (selected) {
        state.selected_stores = state.selected_stores.filter((item) => item !== payload)
      } else {
        state.selected_stores.push(payload)
      }
    },
    setSelectedStores: (state, { payload }: PayloadAction<string[]>) => {
      state.selected_stores = payload
    }
  }
})
export const { actions: reportActions, reducer: reportReducer } = slice
