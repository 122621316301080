import { EntityBase } from 'models/entity'

export enum EnumNoticeTagType {
  BRAND = 'brand', // Thương hiệu: TKL - FM
  LOCATION = 'location', // Kho
  GOOD_SOURCE = 'good_source', // Nguồn hàng: // Nội địa - nhập khẩu
  GOOD_CATEGORY = 'good_category', // Loại hàng: Hàng vải - Nguyên phụ liệu - Thành phẩm
  GOOD_GROUP = 'good_group' // Nhóm hàng: Thời trang - phụ kiện - tiêu dùng
}

export const NoticeTagTypeLabel: Record<EnumNoticeTagType, string> = {
  brand: 'Thương hiệu',
  good_category: 'Loại hàng',
  good_group: 'Nhóm hàng',
  good_source: 'Nguồn hàng',
  location: 'Kho'
}

export interface NoticeTag extends EntityBase {
  type: EnumNoticeTagType
  name: string
  deleted_at: Date
}
