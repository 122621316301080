import { ListEntity, OrgUnitEntity } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const index = async (params: ApiRequest.org_unit.index): Promise<ListEntity<OrgUnitEntity>> =>
  instanceAxios.get('/organization-units', {
    params
  })
const show = async (id: string, params?: ApiRequest.org_unit.show): Promise<OrgUnitEntity> =>
  instanceAxios.get(`/organization-units/${id}`, {
    params
  })

const addSubLeader = async (params?: ApiRequest.org_unit.addSubLeader): Promise<OrgUnitEntity> => instanceAxios.post(`/organization-units/sub-leaders`, params)

const removeSubLeader = async (params?: ApiRequest.org_unit.removeSubLeader): Promise<OrgUnitEntity> =>
  instanceAxios.delete(`/organization-units/sub-leaders`, {
    params
  })

export default {
  index,
  show,
  addSubLeader,
  removeSubLeader
}
