import { Stack, Typography } from '@mui/material'
import React from 'react'
import { COLORS } from 'themes'

export interface TabEntity {
  label: string
  value: string | number
}
interface Props {
  styles?: any
  data: TabEntity[]
  value?: string | number
  onSelect: (a: string | number) => void
}
export function Tabs({ data, value, onSelect, styles }: Props) {
  return (
    <Stack direction={'row'} sx={{ ...(styles ?? {}), alignSelf: 'baseline' }} spacing={3}>
      {data.map((item) => (
        <Stack
          onClick={() => onSelect(item.value)}
          sx={{
            cursor: 'pointer',
            py: '2px',
            borderBottomWidth: '2px',
            px: '10px',
            borderBottomColor: value === item.value ? COLORS.CelticBlue : 'rgba(0,0,0,0)'
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              color: value === item.value ? COLORS.CelticBlue : COLORS.AuroMetalSaurus,
              fontWeight: value === item.value ? '500' : '400'
            }}
          >
            {item.label}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}
