/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EnumExcelTemplate, EnumImportCategory, ExcelTemplate } from 'models'
import { ImportExcelConfig, ImportExcelState } from 'models/redux/import_file'

const initialState: ImportExcelState<any> = {
  category: EnumImportCategory.ALL,
  whilelist_columns: {}
}
const slice = createSlice({
  name: 'IMPORT_EXCEL_STATE',
  initialState,
  reducers: {
    setTemplate: (state, { payload }: PayloadAction<ExcelTemplate | undefined>) => {
      state.template = payload as any
      if (payload?.code) {
        state.whilelist_columns[payload?.code] = payload.columns.map((item) => item.key)
      }
    },
    setConfig: (state, { payload }: PayloadAction<ImportExcelConfig>) => {
      state.config = payload as any
    },
    setFile: (state, { payload }: PayloadAction<File | undefined>) => {
      state.file = payload
      if (payload === undefined) {
        state.is_preview = false
      }
    },
    selectColumn: (state, { payload }: PayloadAction<{ key: string; template: EnumExcelTemplate }>) => {
      const keys = state.whilelist_columns[payload.template] ?? []
      const existed = keys.includes(payload.key)
      state.whilelist_columns[payload.template] = existed ? keys.filter((u) => u !== payload.key) : [...keys, payload.key]
    },
    setPreview: (state, { payload }: PayloadAction<boolean>) => {
      state.is_preview = payload
    },
    selectCategory: (state, { payload }: PayloadAction<EnumImportCategory>) => {
      state.category = payload
    },
    setData: (state, { payload }: PayloadAction<any[]>) => {
      state.data = payload
    }
  }
})
export const { actions: importExcelActions, reducer: importExcelReducer } = slice
