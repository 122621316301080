import { EntityBase } from './entity'

export enum EnumExcelTemplate {
  PRODUCT = 'product',
  COLOR = 'color',
  NOTICE_ITEM = 'notice_item'
}

export interface IColumn {
  key: string
  label: string
  required: boolean
  description: string
}
export interface ExcelTemplate extends EntityBase {
  code: EnumExcelTemplate
  name: string
  columns: IColumn[]
  deleted_at: Date
}
