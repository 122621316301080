/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ClearAllIcon from '@mui/icons-material/ClearAll'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Typography } from '@mui/material'
import { IconButton } from 'components'
import { CategoryEntity, CategoryTypeLabel } from 'models'
import React from 'react'
import { useDispatch } from 'react-redux'
import { COLORS } from 'utils'
export function CatLine({ list, onSelect }: { list: CategoryEntity[]; onSelect: (id: string | undefined) => void }) {
  if (list.length === 0) {
    return <div />
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '8px'
        // backgroundColor: 'red'
      }}
    >
      <IconButton
        label="Xem toàn bộ dòng hàng"
        style={{
          width: '26px',
          height: '26px',
          marginRight: '6px'
        }}
        icon={<ClearAllIcon sx={{ fontSize: '18px', color: COLORS.DarkCharcoal }} />}
        onClick={() => {
          onSelect(undefined)
        }}
      />
      {list.map(({ type, name, id }, index) => (
        <div
          onClick={() => {
            onSelect(id)
          }}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          {index > 0 && (
            <div
              style={{
                padding: '6px'
              }}
            >
              <KeyboardArrowRightIcon />
            </div>
          )}
          {type != null && (
            <Typography
              style={{
                fontSize: '13px',
                fontWeight: '400',
                color: COLORS.nickel,
                marginRight: '2px'
              }}
            >
              {`${CategoryTypeLabel[type]}: `}
            </Typography>
          )}
          <Typography
            style={{
              fontSize: '13px',
              fontWeight: '500',
              color: COLORS.DarkCharcoal,
              cursor: 'pointer'
            }}
          >
            {name}
          </Typography>
        </div>
      ))}
    </div>
  )
}
