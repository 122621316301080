/* eslint-disable no-confusing-arrow */
import { TableColumn } from 'models/table'
import TableOrder from './order'
import TableProduct from './product'
import TableUser from './user'
import TableNoticeArrival from './notice_arrival'
export const TABLE_COLUMNS: Record<string, TableColumn<any>[]> = {
  PRODUCT: TableProduct,
  ORDER_LIST: TableOrder,
  USER_PORTAL: TableUser,
  NOTICE_ARRIVAL: TableNoticeArrival
}
