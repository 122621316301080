/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EnumViewMode, ProductEntity } from 'models'
import { ProductEditState } from 'models/redux/product_edit'
const initialState: ProductEditState = {
  categories_id: []
}
const slice = createSlice({
  name: 'PRODUCT_EDIT_STATE',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<ProductEntity | undefined>) => {
      state.data = payload
      state.mode = payload === undefined ? EnumViewMode.CREATE : EnumViewMode.VIEW
      var cats = payload?.categories ?? []
      if (cats.length > 0) {
        state.leaf_category_id = cats[cats.length - 1].id
        state.categories_id = cats.map((item) => item.id)
      }
    },
    setMode: (state, { payload }: PayloadAction<EnumViewMode>) => {
      state.mode = payload
    },
    setLeafCategoryId: (state, { payload }: PayloadAction<string>) => {
      state.leaf_category_id = payload
    },
    setCategoriesId: (state, { payload }: PayloadAction<string[]>) => {
      state.categories_id = payload
    }
  }
})
export const { actions: productEditAction, reducer: productEditReducer } = slice
