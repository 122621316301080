/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CampaignOrderTab, CategoryEntity, EnumCategoryType, OrderEntity, OrderState, OrderUnitType, OrgUnitEntity } from 'models'
import { ApiRequest } from 'services'

const initialState: OrderState = {
  tab: 'list',
  order_param: {},
  selected_categories: []
}
const slice = createSlice({
  name: 'ORDER_STATE',
  initialState,
  reducers: {
    setImportFile: (state, { payload }: PayloadAction<File | undefined>) => {
      state.import_file = payload
    },
    selectCategory: (state, { payload }: PayloadAction<string | undefined>) => {
      state.category_id = payload
    },
    setSelectedCategories: (state, { payload }: PayloadAction<CategoryEntity[]>) => {
      state.selected_categories = payload
    },
    setOrderUnit: (state, { payload }: PayloadAction<{ type?: OrderUnitType; unit?: OrgUnitEntity }>) => {
      state.order_unit = payload.unit
      state.order_unit_type = payload.type
      if (payload.type !== undefined) {
        state.order_param = {
          is_overall_order: payload.type === 'overall',
          store_unit: payload.type === 'store' ? payload.unit?.id : undefined,
          area_unit: payload.type === 'area' ? payload.unit?.id : undefined
        }
      } else {
        state.order_param = {
          is_overall_order: undefined,
          store_unit: undefined,
          area_unit: undefined
        }
      }
    },
    setOrders: (state, { payload }: PayloadAction<OrderEntity[]>) => {
      state.orders = payload
    },
    setOrderTab: (state, { payload }: PayloadAction<CampaignOrderTab>) => {
      state.tab = payload
    }
  }
})
export const { actions: orderActions, reducer: orderReducer } = slice
