import { CssBaseline, ThemeProvider } from '@mui/material'
import React, { lazy, useCallback, useEffect, useState } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { Loadable, ProtectedAuth } from 'components'
import { GroupPermissionRoutes } from 'components/GroupPermission'
import { BASE_THEME } from 'constant'
import { useSelector } from 'hooks'
import { MenuUrl } from 'models'
import { persistor, profileActions, store } from 'store'
import { CookiesCustom } from 'utils'
import Api from 'services'

const PageNotFound = Loadable(lazy(() => import('pages/404')))
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')))

const NoticeArrivalDetail = Loadable(lazy(() => import('pages/notice_arrival/detail')))
const NoticeArrivalList = Loadable(lazy(() => import('pages/notice_arrival/list')))
const CampaignList = Loadable(lazy(() => import('pages/campaign/list')))
const CampaignEdit = Loadable(lazy(() => import('pages/campaign/edit')))
const StoreList = Loadable(lazy(() => import('pages/location/list')))
const OrgUnitList = Loadable(lazy(() => import('pages/org_unit/list')))
const AreaList = Loadable(lazy(() => import('pages/area/list')))

const UserList = Loadable(lazy(() => import('pages/user_portal/list')))

function AppRoutes() {
  const { user } = useSelector((x) => x.profile)
  const dispatch = useDispatch()
  const has_profile = user != undefined

  useEffect(() => {
    if (has_profile) {
      return
    }
    const token = CookiesCustom.getToken()
    if (!token) {
      return
    }
    Api.me
      .show()
      .then((res: any) => {
        dispatch(profileActions.setProfile(res?.data?.data))
      })
      .catch((e) => {})
  }, [has_profile])

  if (!has_profile) {
    return <div />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/404" element={<PageNotFound />} />
        <Route
          path="/"
          element={
            <ProtectedAuth>
              <MainLayout />
            </ProtectedAuth>
          }
        >
          <Route path={MenuUrl.campaign} element={<CampaignList />} />
          <Route path={`${MenuUrl.campaign}/detail/:id`} element={<CampaignEdit />} />
          <Route path={`${MenuUrl.campaign}/create`} element={<CampaignEdit />} />

          <Route path={MenuUrl.notice_arrival} element={<NoticeArrivalList />} />
          <Route path={`${MenuUrl.notice_arrival}/detail/:id`} element={<NoticeArrivalDetail />} />
          <Route path={`${MenuUrl.notice_arrival}/create`} element={<NoticeArrivalDetail />} />
          <Route path={MenuUrl.user_portal} element={<UserList />} />
          <Route path={MenuUrl.org_unit} element={<OrgUnitList />} />

          {GroupPermissionRoutes.map(({ element, path }) => (
            <Route path={path} element={element} />
          ))}

          <Route path={MenuUrl.location} element={<StoreList />} />

          <Route path={MenuUrl.area} element={<AreaList />} />
        </Route>

        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </BrowserRouter>
  )
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={BASE_THEME}>
          <CssBaseline />
          <AppRoutes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
