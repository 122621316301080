/* eslint-disable no-nested-ternary */
import { Player } from '@lottiefiles/react-lottie-player'
import { Box, Card, Table as MuiTable, TableBody, TableContainer, Typography } from '@mui/material'
import { SearchNoData } from 'assets'
import { EnumTable, TableSelectData } from 'models'
import React, { useEffect } from 'react'
import { SelectedActions } from './selected_actions'
import { Filter } from './filter'
import { TableHeader } from './header'
import { useTableConfig, useTableData } from './hook'
import { MPagination } from './pagination'
import { RowItem } from './row'
import { COLORS } from 'utils'

interface Props {
  code: EnumTable
  onItemAction?: (action: string, item: any) => void
  onSelectedAction?: (action: string, selectedData?: TableSelectData, filter_params?: any) => void
}
export function Table({ code, onSelectedAction, onItemAction }: Props) {
  const { ready } = useTableConfig({ code })
  const { data, table, loading, isExcludedRow, handleRemoteData, handleLocalData } = useTableData(code)

  const boxStyle = {
    width: '100%',
    margin: '60px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
  const table_code = table?.config.code

  useEffect(() => {
    handleRemoteData()
  }, [handleRemoteData])

  useEffect(() => {
    handleLocalData()
  }, [handleLocalData])

  if (!table_code) {
    return <></>
  }
  return (
    <TableContainer
      sx={{
        borderRadius: '6px 6px 0 0'
        // padding: '10px'
      }}
    >
      <Filter code={code} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          my: 1
        }}
      >
        <SelectedActions code={code} onClick={(id) => onSelectedAction?.(id, table?.select, table?.filter)} />
      </Box>
      {!ready ? (
        <> </>
      ) : loading ? (
        <Box sx={boxStyle}>
          <Player autoplay loop src="/lottie_searching.json" style={{ height: '314px', width: '640px' }} />
        </Box>
      ) : !data?.length ? (
        <Box sx={boxStyle}>
          {/* TODO */}
          <SearchNoData />
          <Typography>Không có dữ liệu</Typography>
        </Box>
      ) : (
        <Card sx={{ padding: '12px', mx: '', overflowX: 'scroll', borderWidth: '1px', borderColor: COLORS.nickel, borderRadius: '10px' }}>
          <MuiTable stickyHeader sx={{ minWidth: 750 }} size="small">
            <TableHeader code={code} />
            <TableBody
              sx={{
                maxHeight: '60vh',
                overflow: 'auto'
              }}
            >
              {data.map((item: any) => {
                return isExcludedRow(item) ? <></> : <RowItem data={item} code={code} onAction={onItemAction} />
              })}
            </TableBody>
          </MuiTable>
          <MPagination code={code} />
        </Card>
      )}
    </TableContainer>
  )
}
