import { instanceAxios, transformObjToUrlQuery } from 'utils'
import { ApiRequest } from './interface'

const index = (data: ApiRequest.color.index) => instanceAxios.get(`/colors${transformObjToUrlQuery(data)}`)

const mappingProduct = () => instanceAxios.patch(`/colors/mapping-variants`)
const inCampaign = (campaign: string) => instanceAxios.get(`/colors/export-product-colors?campaign=${campaign}`)
export default {
  index,
  inCampaign,
  mappingProduct
}
