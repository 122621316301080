import {combineReducers, configureStore} from '@reduxjs/toolkit'
import logger from 'redux-logger'
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  campaignReducer,
  importColorReducer,
  importImageReducer,
  importProductReducer,
  noticeArrivalReducer,
  orderReducer,
  profileReducer,
  reportReducer,
  testerReducer,
  uiReducer
} from './slices'
import {commonReducer} from './slices/common'
import {formReducer} from './slices/form'
import {importExcelReducer} from './slices/import_excel'
import {productReducer} from './slices/product'
import {productEditReducer} from './slices/product_edit'
import {tableReducer} from './slices/table'

const isDevEnv = process.env.NODE_ENV !== 'production'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['import_excel', 'notice_arrival']
}

const reducers = combineReducers({
  // UI
  ui: uiReducer,

  // COMMON
  common: commonReducer,
  table: tableReducer,
  form: formReducer,
  profile: profileReducer,
  import_excel: importExcelReducer,

  // CAMPAIGN
  campaign: campaignReducer,
  report: reportReducer,

  // ORDER
  order: orderReducer,

  // PRODUCT
  product: productReducer,
  product_edit: productEditReducer,
  import_product: importProductReducer,
  import_color: importColorReducer,
  import_image: importImageReducer,

  // NOTICE_ARRIVAL
  notice_arrival: noticeArrivalReducer,

  // OTHER
  tester: testerReducer
})
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = [
      ...getDefaultMiddleware({
        thunk: false,
        // serializableCheck: false,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      })
    ]
    if (isDevEnv) {
      middlewares.push(logger as never)
    }
    return middlewares
  },
  devTools: isDevEnv
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof reducers>
