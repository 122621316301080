import { GenderLabelType, OrderEntity, TableColumn } from 'models'

const Table: TableColumn<OrderEntity>[] = [
  {
    header: {
      label: 'Bộ sưu tập'
    },
    cell: {
      getValue: (a: OrderEntity) => {
        const cols = a.product?.collections ?? []
        if (!cols || cols.length === 0) {
          return ' '
        }
        return cols.map((item) => item.name).join(', ')
      },
      width: 100,
      align: 'left'
    }
  },
  {
    header: {
      label: 'Giới tính'
    },
    cell: {
      getValue: (a: OrderEntity) => (a.product?.gender ? GenderLabelType[a.product?.gender] : ''),
      width: 80
    }
  },
  {
    header: {
      label: 'Mã biến thể'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.sku,
      align: 'left'
    }
  },
  {
    header: {
      label: 'Mã thiết kế'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.design_code,
      align: 'left'
    }
  },
  {
    header: {
      label: 'Tên'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.name,
      width: 300,
      align: 'left'
    }
  },
  {
    header: {
      label: 'Màu'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.color ?? '-',
      width: 140,
      align: 'left'
    }
  },
  {
    header: {
      label: 'Size'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.size ?? '-',
      width: 80,
      align: 'left'
    }
  },
  {
    header: {
      label: 'Giá bán'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.price?.toLocaleString(),
      width: 120,
      align: 'right'
    }
  },
  {
    header: {
      label: 'Số lượng'
    },
    cell: {
      getValue: (a: OrderEntity) => a.quantity.toLocaleString(),
      width: 80,
      align: 'right'
    }
  },
  {
    header: {
      label: 'Tổng tiền'
    },
    cell: {
      getValue: (a: OrderEntity) => (a?.quantity * a.product?.price).toLocaleString('vi-VN'),
      width: 120,
      align: 'right'
    }
  },
  {
    header: {
      label: 'Đơn vị đặt'
    },
    cell: {
      getValue: (a: OrderEntity) =>
        a.is_overall_order ? 'Merchandise' : a.area_unit?.mapping_area?.name ?? a.store_unit?.mapping_store?.name ?? 'Không có thông tin',
      width: 220
    }
  }
  // {
  //   header: {
  //     label: 'Người đặt'
  //   },
  //   cell: {
  //     getValue: (a: OrderEntity) => a.order_by?.name ?? ''
  //   }
  // }
]
export default Table
