import { EntityBase } from 'models/entity'
import { UserEntity } from 'models/user_portal'
import { ProductEntity } from 'models/product'
import { NoticeTag } from './tag'

export enum EnumArrivalConvention {
  TYPE_0 = 0,
  TYPE_1 = 1,
  TYPE_2 = 2,
  TYPE_3 = 3,
  TYPE_4 = 4
}

export const ArriveConventionLabel: Record<EnumArrivalConvention, string> = {
  0: '0. LÀ HÀNG MUA NCC KHÔNG HÓA ĐƠN/ CÓ HÓA ĐƠN 1 PHẦN',
  1: '1. LÀ HÀNG MUA/NHẬP CÓ HÓA ĐƠN QUA GIANNI',
  2: '2. LÀ HÀNG MUA/NHẬP CÓ HÓA ĐƠN QUA QUA HSH',
  3: '3. LÀ HÀNG MUA/NHẬP CÓ HÓA ĐƠN QUA INTELLIFE',
  4: '4. LÀ HÀNG MUA/NHẬP CÓ HÓA ĐƠN QUA WINNING'
}
export interface NoticeArrivalItem {
  product: ProductEntity
}

export interface NoticeArrival extends EntityBase {
  receivers: UserEntity[]
  tags: NoticeTag[]
  items: NoticeArrivalItem[]
  is_template?: boolean
  provider_code: string
  arrival_convention: number
  arrival_convention_str: string
  note: string
  dpo_code: string
  categories: string
  brands: string
  quantity: string
  arrival_at: Date
  created_by: UserEntity
  deleted_at: Date
}
