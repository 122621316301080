/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EnumImportCategory, ImageStatusType, ImportImageResult, ImportImageState } from 'models'

const initialState: ImportImageState = {
  design_codes: [],
  uploaded_image_names: [],
  data: [],
  category: 'none',
  results: [],
  chunk_index: 0,
  uploading: false,
  max_upload_count: 100
}
const slice = createSlice({
  name: 'IMPORT_IMAGE_STATE',
  initialState,
  reducers: {
    setDesignCodes: (state, { payload }: PayloadAction<string[]>) => {
      state.design_codes = payload
    },
    setUploading: (state, { payload }: PayloadAction<boolean>) => {
      state.uploading = payload
    },
    setMaxUploadCount: (state, { payload }: PayloadAction<number>) => {
      state.max_upload_count = payload
    },
    setUploadImageNames: (state, { payload }: PayloadAction<string[]>) => {
      state.uploaded_image_names = payload
    },
    selectFiles: (state, { payload }: PayloadAction<File[]>) => {
      state.data = payload
      state.results = []
      state.chunk_index = 0
    },
    selectCategory: (state, { payload }: PayloadAction<ImageStatusType>) => {
      state.category = payload
    },
    uploadComplete: (state, { payload }: PayloadAction<ImportImageResult[]>) => {
      state.results.push(...payload)
    },
    setChunkIndex: (state, { payload }: PayloadAction<number>) => {
      state.chunk_index = payload
    }
  }
})
export const { actions: importImageActions, reducer: importImageReducer } = slice
