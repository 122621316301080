/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CategoryEntity, CommonStateEntity } from 'models'

const initialState: CommonStateEntity = {
  categories: []
}
const slice = createSlice({
  name: 'COMMON_STATE',
  initialState,
  reducers: {
    setCategories: (state, { payload }: PayloadAction<CategoryEntity[]>) => {
      state.categories = payload
    }
  }
})
export const { actions: commonActions, reducer: commonReducer } = slice
