import { ArrowDropDown } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { useSelector } from 'hooks'
import { CampaignSection } from 'models'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { campaignActions } from 'store'

interface Props {
  label: string
  children: any
  id: CampaignSection
}
export function Section({ label, children, id }: Props) {
  const { expand_section } = useSelector((x) => x.campaign)
  const dispatch = useDispatch()

  const expand = expand_section === id
  return (
    <Stack direction="column" sx={{ mb: 3, borderRadius: '10px', overflow: 'hidden' }}>
      <Stack
        direction="row"
        alignItems="center"
        onClick={() => {
          dispatch(campaignActions.setExpandSection(expand ? undefined : id))
        }}
        sx={{
          backgroundColor: '#E5E7EB',
          px: 2,
          py: 2,
          '&:hover': {
            cursor: 'pointer',
            opacity: 0.8
          }
        }}
      >
        <Typography
          style={{
            fontSize: '16px',
            color: '#000',
            fontWeight: '500',
            flex: 1
          }}
        >
          {label}
        </Typography>
        <ArrowDropDown />
      </Stack>
      {expand && <Stack sx={{ backgroundColor: '#fff', px: 2, py: 1 }}>{children}</Stack>}
    </Stack>
  )
}
