/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EnumModule, UIStateEntity } from 'models'

const initialState: UIStateEntity = {
  open_drawer: true,
  loading: false
}
const slice = createSlice({
  name: 'UI_STATE',
  initialState,
  reducers: {
    selectModule: (state, { payload }: PayloadAction<EnumModule | null>) => {
      state.active_module = payload ?? undefined
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setOpenDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.open_drawer = payload
    }
  }
})
export const { actions: uiActions, reducer: uiReducer } = slice
