/* eslint-disable no-return-assign */
import { Box, IconButton as MuiIconButton, Tooltip } from '@mui/material'
import { DetailIcon } from 'assets'
import React from 'react'
import { CustomTooltip } from './CustomTooltip'

export interface IconButtonProps {
  icon: React.JSX.Element
  onClick: (a?: any) => void
  label?: string
  style?: any
}
export function IconButton({ icon, label, onClick, style }: IconButtonProps) {
  return (
    <Tooltip title={label ?? ''} arrow placement="right">
      <Box
        onClick={onClick}
        sx={{
          display: 'flex',
          width: '32px',
          height: '32px',
          borderRadius: '40px',
          backgroundColor: '#E5E7EB',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          ...(style ?? {})
        }}
      >
        {icon}
      </Box>
    </Tooltip>
  )
}

export const viewDetailAction = (getUrl: (a: any) => string): any => ({
  label: 'Xem chi tiết',
  icon: <DetailIcon />,
  onClick: (a: any) => (window.location.href = getUrl(a))
})
