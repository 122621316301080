import { CampaignEntity, EnumCampaignStatus, EnumCampaignType, Response } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const update = (id: string, data: ApiRequest.arrival_notice.create) => instanceAxios.patch(`/notice-arrivals/${id}`, data)
const create = (data: ApiRequest.arrival_notice.create) => instanceAxios.post('/notice-arrivals', data)
const show = (id: string) => instanceAxios.get(`/notice-arrivals/${id}`)
const tagList = () => instanceAxios.get('/notice-arrivals/tags')
const userFind = (emails: string[]) =>
  instanceAxios.get('/notice-arrivals/users', {
    params: {
      emails
    }
  })
export default {
  tags: { index: tagList },
  users: { find: userFind },
  create,
  update,
  show
}
