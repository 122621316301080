/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CategoryEntity, ProductEntity } from 'models'
import { CampaignProductTab, ProductState } from 'models/redux/product'

const initialState: ProductState = {
  tab: 'list',
  selected_categories: [],
  highlight_items: []
}
const slice = createSlice({
  name: 'PRODUCT_STATE',
  initialState,
  reducers: {
    setTab: (state, { payload }: PayloadAction<CampaignProductTab>) => {
      state.tab = payload
    },
    selectCategory: (state, { payload }: PayloadAction<string | undefined>) => {
      state.category_id = payload
    },
    setSelectedCategories: (state, { payload }: PayloadAction<CategoryEntity[]>) => {
      state.selected_categories = payload
    },
    setHighlightItems: (state, { payload }: PayloadAction<ProductEntity[]>) => {
      state.highlight_items = payload
    },
    highlightItem: (state, { payload }: PayloadAction<ProductEntity>) => {
      const index = state.highlight_items.findIndex((item) => item.id === payload?.id)
      if (index !== -1) {
        return
      }
      state.highlight_items.push({ ...payload })
    },
    unhighlightItem: (state, { payload }: PayloadAction<string>) => {
      state.highlight_items = state.highlight_items.filter((item) => item.id !== payload)
    }
  }
})
export const { actions: productActions, reducer: productReducer } = slice
