import { instanceAxios } from 'utils'

const campaignImages = async (id: string): Promise<any> => instanceAxios.get(`/images/campaign/${id}`)
const singleDelete = async (id: string): Promise<boolean> => instanceAxios.delete(`/images/single/${id}`)
export default {
  campaignImages,
  single: {
    delete: singleDelete
  }
}
